import axios from "axios";

const BASE_URL = "https://api.openweathermap.org/data/2.5/weather";
const API_KEY = `11e99e53c20a0fba472a6b9e78786dfb`;

export const fetchDataByLocation = async ({ location, units = "imperial" }) => {
  if (!location) {
    // throw error
  }
  if (!units) {
    // throw error
  }
  const params = new URLSearchParams();
  params.append("units", units);
  params.append("q", location);
  params.append("appid", API_KEY);
  const url = `${BASE_URL}?${params.toString()}`;

  return axios
    .get(url)
    .then((res) => {
      return res?.data || null;
    })
    .catch((err) => {
      return {
        message: err?.response?.data?.message,
        status: err?.response?.status,
      };
    });
};

export const fetchDataByZipcode = async ({
  zipcode,
  countryCode = "US",
  units = "imperial",
}) => {
  if (!zipcode || !countryCode) {
    // throw error
  }
  if (!units) {
    // throw error
  }
  const params = new URLSearchParams();
  params.append("zip", zipcode + countryCode);
  params.append("units", units);
  params.append("appid", API_KEY);
  const url = `${BASE_URL}?${params.toString()}`;

  return axios
    .get(url)
    .then((res) => {
      return res?.data || null;
    })
    .catch((err) => {
      return {
        message: err?.response?.data?.message,
        status: err?.response?.status,
      };
    });
};

export const fetchForecast = async ({
  count = 10,
  city,
  units = "imperial",
}) => {
  if (!city) {
    // throw error
  }
  if (!units) {
    // throw error
  }
  return [
    {
      dt: 1661857200,
      sunrise: 1661834187,
      sunset: 1661882248,
      temp: {
        day: 299.66,
        min: 288.93,
        max: 299.66,
        night: 290.31,
        eve: 297.16,
        morn: 288.93,
      },
      feels_like: {
        day: 299.66,
        night: 290.3,
        eve: 297.1,
        morn: 288.73,
      },
      pressure: 1017,
      humidity: 44,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.7,
      deg: 209,
      gust: 3.58,
      clouds: 53,
      pop: 0.7,
      rain: 2.51,
    },
    {
      dt: 1661943600,
      sunrise: 1661920656,
      sunset: 1661968542,
      temp: {
        day: 295.76,
        min: 287.73,
        max: 295.76,
        night: 289.37,
        eve: 292.76,
        morn: 287.73,
      },
      feels_like: {
        day: 295.64,
        night: 289.45,
        eve: 292.97,
        morn: 287.59,
      },
      pressure: 1014,
      humidity: 60,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.29,
      deg: 215,
      gust: 3.27,
      clouds: 66,
      pop: 0.82,
      rain: 5.32,
    },
    {
      dt: 1662030000,
      sunrise: 1662007126,
      sunset: 1662054835,
      temp: {
        day: 293.38,
        min: 287.06,
        max: 293.38,
        night: 287.06,
        eve: 289.01,
        morn: 287.84,
      },
      feels_like: {
        day: 293.31,
        night: 287.01,
        eve: 289.05,
        morn: 287.85,
      },
      pressure: 1014,
      humidity: 71,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.67,
      deg: 60,
      gust: 2.66,
      clouds: 97,
      pop: 0.84,
      rain: 4.49,
    },
    {
      dt: 1661857200,
      sunrise: 1661834187,
      sunset: 1661882248,
      temp: {
        day: 299.66,
        min: 288.93,
        max: 299.66,
        night: 290.31,
        eve: 297.16,
        morn: 288.93,
      },
      feels_like: {
        day: 299.66,
        night: 290.3,
        eve: 297.1,
        morn: 288.73,
      },
      pressure: 1017,
      humidity: 44,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.7,
      deg: 209,
      gust: 3.58,
      clouds: 53,
      pop: 0.7,
      rain: 2.51,
    },
    {
      dt: 1661943600,
      sunrise: 1661920656,
      sunset: 1661968542,
      temp: {
        day: 295.76,
        min: 287.73,
        max: 295.76,
        night: 289.37,
        eve: 292.76,
        morn: 287.73,
      },
      feels_like: {
        day: 295.64,
        night: 289.45,
        eve: 292.97,
        morn: 287.59,
      },
      pressure: 1014,
      humidity: 60,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.29,
      deg: 215,
      gust: 3.27,
      clouds: 66,
      pop: 0.82,
      rain: 5.32,
    },
    {
      dt: 1662030000,
      sunrise: 1662007126,
      sunset: 1662054835,
      temp: {
        day: 293.38,
        min: 287.06,
        max: 293.38,
        night: 287.06,
        eve: 289.01,
        morn: 287.84,
      },
      feels_like: {
        day: 293.31,
        night: 287.01,
        eve: 289.05,
        morn: 287.85,
      },
      pressure: 1014,
      humidity: 71,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.67,
      deg: 60,
      gust: 2.66,
      clouds: 97,
      pop: 0.84,
      rain: 4.49,
    },
    {
      dt: 1661857200,
      sunrise: 1661834187,
      sunset: 1661882248,
      temp: {
        day: 299.66,
        min: 288.93,
        max: 299.66,
        night: 290.31,
        eve: 297.16,
        morn: 288.93,
      },
      feels_like: {
        day: 299.66,
        night: 290.3,
        eve: 297.1,
        morn: 288.73,
      },
      pressure: 1017,
      humidity: 44,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.7,
      deg: 209,
      gust: 3.58,
      clouds: 53,
      pop: 0.7,
      rain: 2.51,
    },
    {
      dt: 1661943600,
      sunrise: 1661920656,
      sunset: 1661968542,
      temp: {
        day: 295.76,
        min: 287.73,
        max: 295.76,
        night: 289.37,
        eve: 292.76,
        morn: 287.73,
      },
      feels_like: {
        day: 295.64,
        night: 289.45,
        eve: 292.97,
        morn: 287.59,
      },
      pressure: 1014,
      humidity: 60,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.29,
      deg: 215,
      gust: 3.27,
      clouds: 66,
      pop: 0.82,
      rain: 5.32,
    },
    {
      dt: 1662030000,
      sunrise: 1662007126,
      sunset: 1662054835,
      temp: {
        day: 293.38,
        min: 287.06,
        max: 293.38,
        night: 287.06,
        eve: 289.01,
        morn: 287.84,
      },
      feels_like: {
        day: 293.31,
        night: 287.01,
        eve: 289.05,
        morn: 287.85,
      },
      pressure: 1014,
      humidity: 71,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.67,
      deg: 60,
      gust: 2.66,
      clouds: 97,
      pop: 0.84,
      rain: 4.49,
    },
    {
      dt: 1662030000,
      sunrise: 1662007126,
      sunset: 1662054835,
      temp: {
        day: 293.38,
        min: 287.06,
        max: 293.38,
        night: 287.06,
        eve: 289.01,
        morn: 287.84,
      },
      feels_like: {
        day: 293.31,
        night: 287.01,
        eve: 289.05,
        morn: 287.85,
      },
      pressure: 1014,
      humidity: 71,
      weather: [
        {
          id: 500,
          main: "Rain",
          description: "light rain",
          icon: "10d",
        },
      ],
      speed: 2.67,
      deg: 60,
      gust: 2.66,
      clouds: 97,
      pop: 0.84,
      rain: 4.49,
    },
  ];
};
