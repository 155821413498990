import styles from "./styles.module.css";

function CurrentWeather({ data = null, location, units }) {
  if (location === "") {
    return <div className={styles.noData}>Please type a City name</div>;
  }

  if (data === null) {
    return <div className={styles.noData}>Weather data is not available</div>;
  }

  console.log("units", units);

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.location}>
          <p>{data?.name}</p>
        </div>
        <div className={styles.temp}>
          <h1>
            {data?.main?.temp.toFixed()} {units === "Metrics" ? "C" : "F"}
          </h1>
        </div>
        <div className={styles.description}>
          {data?.weather && <p>{data?.weather[0]?.main}</p>}
        </div>
      </div>

      <div className="bottom">
        <div className="feels">
          <p className="bold">
            {data?.main?.feels_like.toFixed()} {units === "Metrics" ? "C" : "F"}
          </p>
          <p>Feels Like</p>
        </div>
        <div className="humidity">
          <p className="bold">{data?.main?.humidity}</p>
          <p>Humidity</p>
        </div>
        <div className="wind">
          <p className="bold">
            {data?.wind?.speed.toFixed()} {units === "Metrics" ? "KMPH" : "MPH"}
          </p>
          <p>Wind Speed</p>
        </div>
      </div>
    </div>
  );
}

export default CurrentWeather;
