import styles from "./styles.module.css";
import { useState } from "react";

function SearchWidget({
  location,
  setLocation,
  searchLocation,
  locationError,
}) {
  const [l, setL] = useState("");

  return (
    <div className={styles.container}>
      <div className={styles.formInput}>
        <label htmlFor="location" />
        <input
          name="location"
          value={l}
          type="text"
          placeholder="Search Location"
          onChange={(event) => setL(event.target.value)}
          onKeyDown={(event) => searchLocation(event, l)}
        />
        {locationError && (
          <div className={styles.formError}>{locationError}</div>
        )}
      </div>

      <button
        onClick={(event) => searchLocation(event, l)}
        className={styles.submitButton}
      >
        Go
      </button>
    </div>
  );
}

export default SearchWidget;
