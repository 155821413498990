import { useState } from "react";
import styles from "./styles.module.css";

function ToggleButton({ callback }) {
  const [isToggled, setToggled] = useState(false);
  const units = isToggled ? "Imperial" : "Metrics";
  callback(units);

  const handleToggle = () => {
    setToggled(!isToggled);
  };

  return (
    <div className={styles.container}>
      <button
        className={`${styles.button} ${isToggled ? styles.toggled : ""}`}
        onClick={handleToggle}
      >
        {units}
      </button>
    </div>
  );
}

export default ToggleButton;
