import "./index.css";
import { useState, useEffect } from "react";

// api
import { fetchDataByLocation, fetchForecast } from "./api";

// components
import Toggle from "./components/ToggleButton";
import SearchWidget from "./components/SearchWidget";
import CurrentWeather from "./components/CurrentWeather";
import ForeCast from "./components/ForeCast";

// hook
import useLocalStorage from "./utils/useLocalStorage";

function App() {
  const [data, setData] = useState("data", "");
  const [location, setLocation] = useLocalStorage("location", "");
  const [forecast, setForecast] = useState([]);
  const [error, setError] = useState(null);
  const [units, setUnits] = useLocalStorage("units", "Metrics");

  const searchLocation = (event, location) => {
    if (location === "") {
      setError("Please enter a valid city name");
      return;
    }
    setError("");

    if (event.key === "Enter") {
      setLocation(location);
    }
  };

  useEffect(() => {
    if (location === "") {
      return;
    }
    fetchDataByLocation({ location, units })
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });

    fetchForecast({ location })
      .then((res) => {
        setForecast(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location, units]);

  return (
    <div className="app">
      <Toggle callback={setUnits} />
      <SearchWidget
        location={location}
        setLocation={setLocation}
        searchLocation={searchLocation}
        locationError={error}
      />
      <CurrentWeather data={data} units={units} location={location} />
      <ForeCast list={forecast} location={location} units={units} />
    </div>
  );
}

export default App;
