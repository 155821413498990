const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function formatDate(epochTimestamp) {
  const milliseconds = epochTimestamp * 1000;

  const date = new Date(milliseconds);

  const dayOfWeek = date.getDay();

  const dayString = daysOfWeek[dayOfWeek];

  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear();

  const ordinalSuffix = getOrdinalSuffix(day);

  const dateString = `${month} ${day}${ordinalSuffix}, ${year}`;

  return { date: dateString, day: dayString };
}
