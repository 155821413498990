import styles from "./styles.module.css";

import { formatDate } from "../../utils";

function Forecast({ list = [], location = "", units }) {
  if (location === "") {
    return null;
  }
  if (list.length === 0) {
    return <div className={styles.noData}>Forecast data is not available</div>;
  }
  return (
    <div className={styles.container}>
      {list.map((dayElement, index) => {
        const { day, date } = formatDate(dayElement.dt);
        return (
          <div className={styles.card} key={index + day}>
            <p>{date}</p>
            <p>{day}</p>
            <p>{dayElement.weather[0].main}</p>
            <p>
              Min - {dayElement.temp.min} {units === "Metrics" ? "C" : "F"}
            </p>
            <p>
              Max - {dayElement.temp.max} {units === "Metrics" ? "C" : "F"}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default Forecast;
